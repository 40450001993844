import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"white--text",attrs:{"color":"red","text":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-trash-can")]),_vm._v(" Supprimer la saisie ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VToolbar,{attrs:{"color":"red"}},[_c(VToolbarTitle,{staticClass:"white--text"},[_vm._v("Supprimer la saisie")])],1),_c(VCardText,{staticClass:"pa-4"},[_vm._v(" Supprimer cette saisie ? Cela supprimera tous les règlements associés ! ")]),_c(VCardActions,[_c(VBtn,{staticClass:"white--text",attrs:{"color":"red","disabled":_vm.deleting},on:{"click":_vm.deleteSaisie}},[_vm._v("Confirmer")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }