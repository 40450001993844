<template>
  <v-container>
    <v-row no-gutters justify="center" align="center" v-if="loading">
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
    </v-row>
    <v-row v-else-if="!loading && !dossier">
      <v-alert
          type="error"
      >Une erreur est survenue, impossible de trouver le dossier.
      </v-alert>
    </v-row>
    <div v-else>
      <v-tabs v-model="tab">
        <v-tab>Général</v-tab>
        <v-tab>Appels de fonds</v-tab>
        <v-tab>Suivi des financements</v-tab>
        <v-tab>Suivi des factures</v-tab>
        <v-tab>Décaissements</v-tab>
        <v-tab>Encaissements</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-toolbar>
            <v-toolbar-title class="primary--text">
              Statut financier du dossier de {{ dossier.beneficiaire_first_name }} {{ dossier.beneficiaire_last_name }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn :to="'/dossier/' + dossier.id" color="primary" text>
                <v-icon>mdi-arrow-left</v-icon>
                Retour au dossier
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-row class="mt-4" no-gutters>
            <v-col cols="12" class="text-center">
              <h3 class="primary--text">RÉALISÉ</h3>
            </v-col>
            <v-col cols="12" md="6">
              <StatCard color="primary" title="Total encaissements"
                        :value="dossier.total_encaissements.toFixed(2) + ' €'"></StatCard>
            </v-col>
            <v-col cols="12" md="6">
              <StatCard color="secondary" title="Total décaissements"
                        :value="dossier.total_decaissements.toFixed(2) + ' €'">

              </StatCard>
            </v-col>
            <v-col cols="12" class="text-center">
              <h3 class="primary--text">BUDGET</h3>
            </v-col>
            <v-col cols="12" md="6">
              <StatCard color="primary" title="Total des ressources"
                        :value="dossier.total_finance.toFixed(2) + ' €' "></StatCard>
            </v-col>
            <v-col cols="12" md="6">
              <StatCard color="secondary" title="Total des emplois"
                        :value="dossier.total_emplois.toFixed(2) + ' €'"></StatCard>
            </v-col>
            <v-col cols="12" md="6">
              <h3 class="primary--text text-center">RESSOURCES</h3>
              <Subventions @reload="reloadFinance" :read-only="true" :dossier="dossier"/>
            </v-col>
            <v-col cols="12" md="6">
              <h3 class="primary--text text-center">EMPLOIS</h3>
              <Honoraires @reload="reloadFinance" :read-only="true" :dossier="dossier"/>
            </v-col>
          </v-row>
          <v-row no-gutters class="my-4">
            <v-col md="4" cols="6" v-if="dossier.montant_travaux > 0">
              <v-card :to="`/travaux/` + dossier.id" class="d-flex flex-column">
                <v-icon color="primary" size="192">mdi-hammer-wrench</v-icon>
                <v-btn text color="primary">Accéder aux travaux</v-btn>
              </v-card>
            </v-col>
          </v-row>

        </v-tab-item>
        <v-tab-item>
          <BaseDocumentaire class="mt-4" :finances_only="true" @reload="reloadFinance" :dossier="dossier"/>
        </v-tab-item>
        <v-tab-item>
          <SuiviFinancement @reload="reloadFinance" :dossier="dossier"/>
        </v-tab-item>
        <v-tab-item>
          <SuiviFacturation @reload="reloadFinance" :dossier="dossier"/>
        </v-tab-item>
        <v-tab-item>
          <SaisieTableDisplay type="Décaissement" @reload="reloadFinance" :dossier="dossier"/>
        </v-tab-item>
        <v-tab-item>
          <SaisieTableDisplay type="Encaissement" @reload="reloadFinance" :dossier="dossier"/>
        </v-tab-item>

      </v-tabs-items>
    </div>
  </v-container>
</template>

<script>
import StatCard from "@/components/base/StatCard.vue";
import SuiviFinancement from "@/views/SuiviFinancement.vue";
import Subventions from "@/views/Subventions.vue";
import Honoraires from "@/views/Honoraires.vue";
import SaisieTableDisplay from "@/views/SaisieTableDisplay.vue";
import BaseDocumentaire from "@/views/BaseDocumentaire.vue";
import SuiviFacturation from "@/views/SuiviFacturation.vue";

export default {
  name: "FinancierInfos",
  components: {BaseDocumentaire, SaisieTableDisplay, Honoraires, Subventions, SuiviFinancement, StatCard, SuiviFacturation},
  async mounted() {
    try {
      let id = this.$route.params?.id;
      this.dossier = await this.$store.dispatch("dossier/getDossierFinanceData", id);
    } finally {
      this.loading = false;
    }
  },
  data() {
    return {
      loading: true,
      dossier: null,
      tab: 0,
    }
  },
  computed: {
    encaissements() {
      return this.dossier?.saisies.filter(saisie => saisie.type === "Encaissement");
    },
    decaissements() {
      return this.dossier?.saisies.filter(saisie => saisie.type === "Décaissement");
    }
  },
  methods: {
    async reloadFinance() {
      console.log("reloadFinance");
      let id = this.$route.params?.id;
      this.dossier = await this.$store.dispatch("dossier/getDossierFinanceData", id);
    }
  }
}
</script>

