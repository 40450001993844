<template>
  <div>
    <v-toolbar>
      <v-toolbar-title class="primary--text">
        Suivi des financements du dossier de {{ dossier.beneficiaire_first_name }}
        {{ dossier.beneficiaire_last_name }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn :to="'/dossier/' + dossier.id" color="primary" text>
          <v-icon>mdi-arrow-left</v-icon>
          Retour au dossier
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <div class="pa-4" v-if="getFondsPropesPaymentStatus">
      <p class="h2 primary--text font-weight-bold">Tout les fonds propres ont étés reçus sur ce dossier.</p>
    </div>
    <div class="my-4" v-for="subvention in subventions" :key="subvention.origine">
      <v-row class="pa-4" no-gutters justify="space-between">
        <h2 class="primary--text">{{ subvention.origine }}</h2>
        <div>
          <span class="primary--text font-weight-bold body-1">Total : {{ parseFloat(subvention.montant).toFixed(2) }} €</span><br>
          <span class="body-2">Reste à percevoir : {{getRemainingToReceive(subvention).toFixed(2)}} €</span><br>
          <span v-if="subvention.origine !== 'Apport personnel'" class="body-2">Total demandé : {{getAsked(subvention).toFixed(2)}} €<br></span>
          <span v-if="subvention.origine !== 'Apport personnel'" class="body-2">Reste à demander : {{getRemaining(subvention).toFixed(2)}} €<br></span>
        </div>
      </v-row>
      <v-simple-table dense>
        <template>
          <thead>
          <tr>
            <th>Source</th>
            <th>Date de demande</th>
            <th>Provenance</th>
            <th>Montant</th>
            <th>Statut</th>
            <th>Justificatif</th>
            <th>Actions</th>
          </tr>
          </thead>
          <tbody v-for="saisie in getDemandes(subvention.origine)" :key="saisie.id">
          <tr v-for="reglement in saisie.reglements" :key="reglement.id">
            <td>{{ saisie.source }}</td>
            <td>{{ reglement.date_demande }}</td>
            <td>{{reglement.provenance}}</td>
            <td>{{ reglement.montant }} €</td>
            <td>
              <reglement-status-chip  :reglement="reglement"></reglement-status-chip>
            </td>
            <td>
              <v-btn target="_blank" :href="getUrl(reglement.file)" icon color="primary" v-if="reglement.file">
                <v-icon>mdi-paperclip</v-icon>
              </v-btn>
            </td>
            <td>
              <switch-reglement-status-menu @reload="$emit('reload')" :reglement="reglement"></switch-reglement-status-menu>
              <v-btn color="red" icon @click="deleteReglement(reglement)"><v-icon>mdi-trash-can</v-icon></v-btn>
              <ReglementForm :icon="true" :reglement="reglement" @reload="$emit('reload')" :saisie="{'type': 'Encaissement', 'id': reglement.saisie, 'source': reglement.saisie_source}" operation="update"/>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-row no-gutters justify="end">
        <AddDemandeReglement @reload="$emit('reload')" :dossier="dossier" :sources="getSources(subvention.origine)" :montant="parseFloat(subvention.montant)" />
      </v-row>
    </div>


  </div>
</template>
<script>
import ReglementStatusChip from "@/components/finances/ReglementStatusChip.vue";
import * as config from "@/config.json";
import AddDemandeReglement from "@/views/AddDemandeReglement.vue";
import {ReglementRepository} from "@/repository/reglement";
import SwitchReglementStatusMenu from "@/views/SwitchReglementStatusMenu.vue";
import ReglementForm from "@/views/ReglementForm.vue";

const SUBVENTION_ENCAISSEMENTS = [
  {name: "Subvention LBU", saisies: ["DEAL - LBU Travaux", "DEAL - LBU Complémentaire", "DEAL - LBU MOE"]},
  {name: "Subvention CTM", saisies: ["CTM - SUBVENTION"]},
  {name: "Subvention Espace Sud", saisies: ["ESPACE SUD"]},
  {name: "Subvention DEAL AISFT", saisies: ["DEAL - AISFT Arrêté", "DEAL - AISFT Travaux"]},
  {name: "Apport personnel", saisies: ["BENEFICIAIRE - APPORT"]},
  {name: "Prêt Martinique Habitat", saisies: ["MARTINIQUE HABITAT"]},
]
export default {
  name: 'SuiviFinancement',
  components: {ReglementForm, SwitchReglementStatusMenu, AddDemandeReglement, ReglementStatusChip},
  props: {
    dossier: {}
  },
  data() {
    return {
      deleting: false,
    }
  },
  computed: {
    subventions() {
      if (!this.dossier.financements) return [];
      let generated = this.dossier.financements.filter((f) => f.origine.toLowerCase().indexOf("subvention") !== -1
          || f.origine.toLowerCase().indexOf("apport personnel") !== -1
          || f.origine.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().indexOf("pret") !== -1);
      return [
        //{origine: "Subvention état travaux", montant: this.dossier.subvention_etat_travaux},
        {origine: "Subvention LBU", montant: this.dossier.subvention_etat_travaux + this.dossier.maitrise_oeuvre},
        {origine: "Subvention DEAL AISFT", montant: 6000},
        //{origine: "Subvention maîtrise d'oeuvre", montant: this.dossier.maitrise_oeuvre},


        ...generated]
    },
    getFondsPropesPaymentStatus() {
      let fondsPropres = this.subventions.filter(subvention => !["Subvention LBU", "Subvention DEAL", "Subvention DEAL AISFT"].includes(subvention.origine));
      for(let subvention of fondsPropres) {
        console.log(subvention.origine + " RESTANT : " + this.getRemainingToReceive(subvention))
        if(this.getRemainingToReceive(subvention) !== 0) {
          return false;
        }
      }
      return true;
    }
  },
  methods: {
    getDemandes(subvention) {
      let lot = SUBVENTION_ENCAISSEMENTS.find((demande) => demande.name === subvention);
      if (lot) {
        return this.dossier.saisies.filter(saisie => lot.saisies.includes(saisie.source));
      } else {
        return [];
      }
    },
    getSources(subvention) {
      let lot = SUBVENTION_ENCAISSEMENTS.find((demande) => demande.name === subvention);
      if(lot) {
        return lot.saisies;
      }
      else {
        return [];
      }
    },
    getRemaining(subvention) {
      let total = 0;
      let saisies = this.getDemandes(subvention.origine);
      for(const saisie of saisies) {
        for(const reglement of saisie.reglements) {
          total += parseFloat(reglement.montant);
        }
      }
      return parseFloat(subvention.montant) - total;
    },
    getRemainingToReceive(subvention) {
      let total = 0;
      let saisies = this.getDemandes(subvention.origine);
      for(const saisie of saisies) {
        for(const reglement of saisie.reglements) {
          if(reglement.status === "paid") {
            total += parseFloat(reglement.montant);
          }
        }
      }
      return parseFloat(subvention.montant) - total;
    },
    getAsked(subvention) {
      let total = 0;
      let saisies = this.getDemandes(subvention.origine);
      for(const saisie of saisies) {
        for(const reglement of saisie.reglements) {
          total += parseFloat(reglement.montant);
        }
      }
      return total;
    },
    getUrl(path) {
      return config.BASE_URL + path;
    },
    async deleteReglement(reglement) {
      this.deleting = true;
      try {
        let repository = new ReglementRepository();
        let result = await repository.delete(reglement);
        if (result === "success") {
          this.$store.dispatch("annonce/annonceSuccess", "Règlement supprimé !");
          this.$emit("reload");
        }
      } finally {
        this.deleting = false;
      }
    },
  },

}
</script>