<template>
  <div>
    <v-toolbar>
      <v-toolbar-title class="primary--text">
        Suivi des factures du dossier de {{ dossier.beneficiaire_first_name }}
        {{ dossier.beneficiaire_last_name }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn :to="'/dossier/' + dossier.id" color="primary" text>
          <v-icon>mdi-arrow-left</v-icon>
          Retour au dossier
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-row class="mt-4" justify="end" no-gutters>
        <HonoraireForm @reload="$emit('reload')" :dossier="dossier"/>
      </v-row>
    <div class="my-4" v-for="facture in factures" :key="facture.origine">
      <v-row class="pa-4" no-gutters justify="space-between">
        <h2 class="primary--text">{{ facture.origine }}</h2>
        <div>
          <span class="primary--text font-weight-bold body-1">Total : {{ parseFloat(facture.montant).toFixed(2) }} €</span><br>
          <span class="body-2">Reste à percevoir : {{getRemainingToReceive(facture).toFixed(2)}} €</span><br>
          <span v-if="facture.origine !== 'Apport personnel'" class="body-2">Total demandé : {{getAsked(facture).toFixed(2)}} €<br></span>
          <span v-if="facture.origine !== 'Apport personnel'" class="body-2">Reste à demander : {{getRemaining(facture).toFixed(2)}} €<br></span>
        </div>
      </v-row>
      <v-simple-table dense>
        <template>
          <thead>
          <tr>
            <th>Source</th>
            <th>Date de demande</th>
            <th>Provenance</th>
            <th>Montant</th>
            <th>Statut</th>
            <th>Justificatif</th>
            <th>Actions</th>
          </tr>
          </thead>
          <tbody v-for="saisie in getDemandes(facture.origine)" :key="saisie.id">
          <tr v-for="reglement in saisie.reglements" :key="reglement.id">
            <td>{{ saisie.source }}</td>
            <td>{{ getFormattedDate(reglement.date_facture) }}</td>
            <td>{{reglement.entreprise_name}}</td>
            <td>{{ reglement.montant }} €</td>
            <td>
              <reglement-status-chip  :reglement="reglement"></reglement-status-chip>
            </td>
            <td>
              <v-btn target="_blank" :href="getUrl(reglement.file)" icon color="primary" v-if="reglement.file">
                <v-icon>mdi-paperclip</v-icon>
              </v-btn>
            </td>
            <td>
              <switch-reglement-status-menu @reload="$emit('reload')" :reglement="reglement"></switch-reglement-status-menu>
              <v-btn color="red" icon @click="deleteReglement(reglement)"><v-icon>mdi-trash-can</v-icon></v-btn>
              <ReglementForm :icon="true" :reglement="reglement" @reload="$emit('reload')" :saisie="{'type': 'Décaissement', 'id': reglement.saisie, 'source': reglement.saisie_source}" operation="update"/>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-row no-gutters justify="end">
<!--        <AddDemandeReglement @reload="$emit('reload')" :dossier="dossier" :sources="getSources(facture.origine)" :montant="parseFloat(facture.montant)" />-->
      </v-row>
    </div>


  </div>
</template>
<script>
import ReglementStatusChip from "@/components/finances/ReglementStatusChip.vue";
import * as config from "@/config.json";
import AddDemandeReglement from "@/views/AddDemandeReglement.vue";
import {ReglementRepository} from "@/repository/reglement";
import SwitchReglementStatusMenu from "@/views/SwitchReglementStatusMenu.vue";
import ReglementForm from "@/views/ReglementForm.vue";
import {getFormattedDate} from "../util";
import HonoraireForm from "@/views/HonoraireForm.vue";

const FACTURE_DECAISSEMENTS = [
  {name: "Mission AISFT", saisies: ["AISFT"]},
  {name: "Maitrîse d'oeuvre", saisies: ["MOE"]},
]
export default {
  name: 'SuiviFacturation',
  // eslint-disable-next-line vue/no-unused-components
  components: {HonoraireForm, ReglementForm, SwitchReglementStatusMenu, AddDemandeReglement, ReglementStatusChip},
  props: {
    dossier: {}
  },
  data() {
    return {
      deleting: false,
    }
  },
  computed: {
    factures() {
      return [
        {origine: "Mission AISFT", montant: 8500},
        {origine: "Maitrîse d'oeuvre", montant: this.dossier.maitrise_oeuvre},
      ]
    },
  },
  methods: {
    getFormattedDate,
    getDemandes(subvention) {
      let lot = FACTURE_DECAISSEMENTS.find((demande) => demande.name === subvention);
      if (lot) {
        return this.dossier.saisies.filter(saisie => lot.saisies.includes(saisie.source));
      } else {
        return [];
      }
    },
    getSources(subvention) {
      let lot = FACTURE_DECAISSEMENTS.find((demande) => demande.name === subvention);
      if(lot) {
        return lot.saisies;
      }
      else {
        return [];
      }
    },
    getRemaining(subvention) {
      let total = 0;
      let saisies = this.getDemandes(subvention.origine);
      for(const saisie of saisies) {
        for(const reglement of saisie.reglements) {
          total += parseFloat(reglement.montant);
        }
      }
      return parseFloat(subvention.montant) - total;
    },
    getRemainingToReceive(subvention) {
      let total = 0;
      let saisies = this.getDemandes(subvention.origine);
      for(const saisie of saisies) {
        for(const reglement of saisie.reglements) {
          if(reglement.status === "paid") {
            total += parseFloat(reglement.montant);
          }
        }
      }
      return parseFloat(subvention.montant) - total;
    },
    getAsked(subvention) {
      let total = 0;
      let saisies = this.getDemandes(subvention.origine);
      for(const saisie of saisies) {
        for(const reglement of saisie.reglements) {
          total += parseFloat(reglement.montant);
        }
      }
      return total;
    },
    getUrl(path) {
      return config.BASE_URL + path;
    },
    async deleteReglement(reglement) {
      this.deleting = true;
      try {
        let repository = new ReglementRepository();
        let result = await repository.delete(reglement);
        if (result === "success") {
          this.$store.dispatch("annonce/annonceSuccess", "Règlement supprimé !");
          this.$emit("reload");
        }
      } finally {
        this.deleting = false;
      }
    },
  },

}
</script>